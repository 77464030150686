/**
 * Class element
 * Bottom menu tablet component
 * Display a menu element in the bottom of nav tablet
 */
/* Import section */
import { Link } from "gatsby"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsBoxArrowRight } from "react-icons/bs"
import Ctabutton from "../components/ctabutton"
import MenuNavJSon from "../../content/menu-nav.json"
import "../styles/components/bottommenutablet.scss"

/* Declaration class */
class Bottommenutablet extends Component {

  constructor(props) {
    super(props)
    this.state = {
      onClicked: false
    }
    this.handlerButtonOnClick = this.handlerButtonOnClick.bind(this)
  }

  handlerButtonOnClick(){
    document.body.style.overflow = 'auto';
    this.setState({
       onClicked: true
    })
  }

  render () {

  return (
    <div className="bottom-menu-tablet">
      <span className="separator"></span>
      <Ctabutton idCta="ctaGetstarted" classCta="cta-getstarted" colorStyle="blue-light-format" ctaLabel="Get Started" ctaLink="/get-started" />
      <ul className="secondary-nav-tablet">
        <li><a className="cta-userlogin tablet" href="https://user.hellohealth.com/" target="_blank" rel="noreferrer">Login</a></li>
        <li><Link className="cta-support tablet" onClick={this.handlerButtonOnClick} to={MenuNavJSon.simpleMenuItem[2].to}>{MenuNavJSon.simpleMenuItem[2].cta}</Link></li>
        <li><Link onClick={this.handlerButtonOnClick} to={MenuNavJSon.simpleMenuItem[3].to}>{MenuNavJSon.simpleMenuItem[3].cta}</Link></li>
        <li className="portalconnect">
            <a className="cta-portalconnect tablet" href="https://portalconnect.net/login/" target="_blank" rel="noreferrer">
              <span className="text-pc">Patient Portal</span>
              <span className="icon-pc">
                <span>
                <IconContext.Provider value={{ className: "svg-pc" }}>
                  <BsBoxArrowRight />
                </IconContext.Provider>
                </span>
              </span>
            </a>
          </li>
      </ul>
    </div>
  )
  }
}

/* Export function */
export default Bottommenutablet
