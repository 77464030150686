/**
 * Const with no params element
 * Header Top nav component
 * Display nav on top of header in desktop
 */
/* Import section */
import { Link } from "gatsby"
import React from "react"
import { IconContext } from "react-icons"
import { BsPersonFill, BsBoxArrowRight } from "react-icons/bs"
import MenuNavJSon from "../../content/menu-nav.json"
import "../styles/components/headertop.scss"

/* Declaration function */
const Headertop = () => {
  return (
    <div className="top-header">
      <div className="wrap-top-header">
        <ul className="navtop">
        <li className="portalconnect">
            <a className="cta-portalconnect" href="https://portalconnect.net/login/" target="_blank" rel="noreferrer">
              <span className="text-pc">Patient Portal</span>
              <span className="icon-pc">
                <span>
                <IconContext.Provider value={{ className: "svg-pc" }}>
                  <BsBoxArrowRight />
                </IconContext.Provider>
                </span>
              </span>
            </a>
          </li>
          <li className="covid"><Link to={MenuNavJSon.simpleMenuItem[3].to}>{MenuNavJSon.simpleMenuItem[3].cta}</Link></li>
          <li><Link className="cta-support" to={MenuNavJSon.simpleMenuItem[2].to}>{MenuNavJSon.simpleMenuItem[2].cta}</Link></li>
          <li className="separator"></li>
          <li className="login">
            <a className="cta-userlogin" href="https://user.hellohealth.com/" target="_blank" rel="noreferrer">
              <span className="icon-login">
                <span>
                <IconContext.Provider value={{ className: "svg-login" }}>
                  <BsPersonFill />
                </IconContext.Provider>
                </span>
              </span>
              <span className="text-login">Login</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

/* Export function */
export default Headertop